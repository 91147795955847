@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&Lato:400,70&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important; */
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

:root {
  --coral: #f44b48;
  --greyblue: #62c2d0;
  --white: #ffffff;
  --twilight-blue: #0d549b;
  --brown-grey: #a3a3a3;
  --black: #000000;
  --fresh-green: #56df46;
  --brownish-grey: #524e4e;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.openRotation {
  -webkit-transform: rotateY(-180deg) translateX(9px);
          transform: rotateY(-180deg) translateX(9px);
}

.ui.dropdown.RankClusterDropdown .text {
  width: 85% !important;
  color: #f44b48 !important;
  font-weight: 300 !important;
}

.ui.dropdown.RankClusterDropdown .menu {
  background-color: white !important;
}

.ui.dropdown.RankClusterDropdown .icon {
  width: 21px !important;
  height: 12px !important;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.ui.dropdown.RankClusterDropdown.active .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.ui.dropdown.RankClusterDropdown .icon::before {
  content: "" !important;
}

/* admin */

.admin-page .ui.disabled.dropdown.RankClusterDropdown {
  opacity: 1;
}

.admin-page .ui.disabled.dropdown.CustomDropdown.RankClusterDropdown img {
  opacity: 0;
}

.admin-page .clusters-container {
  opacity: 1 !important;
}

.admin-page .dotted-circle-container-hover {
  background-color: transparent !important;
  border-color: rgb(13, 84, 155) !important;
  border-width: 1px !important;
  border-style: dashed !important;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.24);
}

.admin-page .dotted-circle-container-active {
  background-color: rgb(13, 84, 155) !important;
}

